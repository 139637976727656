/* $cash_color: #34495e; */
$actuarial_color: #009858;
$analytics_color: #dc3545;
$assets_color: #d63384;
$cash_color: #00bd6e;
$crm_color: #c8003c;
$danger: #db0041;
$dark: #1f1f1f;
$dbcloner_color: #303952;
$document_color: #0bbcc8;
$enable-responsive-font-sizes: true;
$evolutics_background_gray: #e5e5e5;
$finance_color: #34495e;
$general_color: #ab00c8;
$general: $general_color;
$health_color: #3498db;
$human_color: #cadb34;
$primary: #514ef5;
$process_color: #b3956d;
$secondary: #e67e22;
$success: #2ecc71;
$warning: #f39c12;
//
$red: #dc3545;
