$icomoon-font-family: "evolustics" !default;
$icomoon-font-path: "fonts" !default;

$evo-setting: "\e915";
$evo-agent: "\e900";
$evo-angledown: "\e901";
$evo-blankdoc: "\e902";
$evo-debitcards: "\e903";
$evo-find: "\e904";
$evo-folder: "\e905";
$evo-uptrend: "\e906";
$evo-cards: "\e907";
$evo-heart: "\e908";
$evo-integral: "\e909";
$evo-linegraph: "\e90a";
$evo-menu: "\e90b";
$evo-multidoc: "\e90c";
$evo-piechart: "\e90d";
$evo-profile: "\e90e";
$evo-user: "\e90f";
$evo-admin: "\e910";
$evo-pulse: "\e911";
$evo-receipt: "\e912";
$evo-truck: "\e913";
$evo-wallet: "\e914";

