@import 'variables';

.section-cards {
  .card:after {
    content: '';
    width: 80px;
    height: 60px;
    border-top-right-radius: 5rem;
    border-bottom-left-radius: 20px;
    position: absolute;
    bottom: 0;
    background-color: #0000004a;
  }
}
.text-info {
  color: #0aa1bf !important;
}
.bg-info {
  background-color: #0fa1bf !important;
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 7px 2px #e7e7e7;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--primary);
  background-color: transparent;
}
.btn-primary {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-primary:hover {
  color: rgb(232, 230, 227);
  background-color: var(--primary);
  border-color: var(--primary);
  background-image: linear-gradient(45deg, #0000001a, #0000001a);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--primary);
}
.nav-gray {
  button.active {
    color: var(--primary) !important;
    background-color: #fff !important;
  }
}
.nav-link {
  color: var(--primary);
}
.text-primary {
  color: var(--primary) !important;
}
.bg-primary {
  background-color: var(--primary) !important;
}
.section-cards.card-blue,
.section-cards {
  .card,
  .btn-large {
    background: var(--primary);
    color: white;
  }
}
.section-cards.card-blue,
.section-cards {
  .card.color1:after {
    background: #0000004a;
  }
}
input[type='checkbox']:checked {
  background-color: var(--primary) !important;
}
.control-bg-primary {
  background-color: var(--primary);
  &:focus {
    background-image: linear-gradient(45deg, #00000026, #00000026);
    background-color: var(--primary);
  }
}
.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}
.mat-tab-label-content {
  color: var(--primary);
}
.mat-tab-label-active {
  background-color: var(--primary);
}
